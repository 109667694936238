<template>
  <div class="main-page">
    <div class="container px-3">
      <div class="row">
        <div class="col-md-5 col-md-push-7 vh-100 d-flex">
          <div class="form-login w-100">
            <form @submit.prevent="submit">
              <div class="d-flex flex-column align-items-center">
                <div class="mb-4">
                  <img src="@/assets/icon/otp.svg" alt="">
                </div>
                <span class="fs-24 fw-700 color-neutral-900">
                  Verifikasi
                </span>
                <p class="fs-14 fw-400 color-neutral-600 text-center px-3">
                  Silahkan masukkan kode OTP yang sudah dikirimkan ke Nomor WhatsApp anda.
                </p>
              </div>

              <div>
                <label>Masukkan Kode</label>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    v-model="kode_otp"
                    maxlength="4"
                    placeholder="_ _ _ _"
                    class="form-control"
                    :class="{ 'is-invalid': errorMessage }"
                    style="letter-spacing: 10px;"
                  >
                  <span class="input-group-text bg-grayscale border-0" :class="{ 'is-invalid': errorMessage }">
                    <div class="show-password" type="button" @click="kode_otp = ''">
                      <i class="ri-close-circle-fill fs-24"></i>
                    </div>
                  </span>
                  <span class="invalid-feedback">{{ errorMessage }}</span>
                </div>
              </div>

              <div class="w-100 text-center">
                <button v-if="loading.otp" type="button" class="btn btn-primary w-100" disabled>
                  <span class="fs-16 fw-600 text-white">Loading</span>
                  <span class="spinner-border" role="status"></span>
                </button>
                <button v-else type="submit" class="btn btn-primary w-100" :disabled="!kode_otp">
                  <span class="fs-16 fw-600 text-white">Verifikasi</span>
                </button>
              </div>

              <div class="d-flex flex-column justify-content-center align-items-center mt-3">
                <span class="d-block fs-14 fw-400 color-neutral-600 mb-3">{{ (loading.otp) ? 'Mengirim ulang..':'Belum menerima kode ?' }}</span>
                <span v-if="countDown > 0" class="fs-14 fw-700 color-neutral-900">{{ countDown }}</span>
                <a v-else-if="!loading.otp" href="#" class="fs-14 fw-700 color-secondary-500" @click="resend">
                  Kirim Ulang
                </a>
                <span v-else class="spinner-border align-middle" role="status"></span>
              </div>
            </form>
          </div>
        </div>

        <div class="col-md-7">
          <div class="frame-image vh-100 d-flex align-items-center">
            <div class="ps-5">
              <img src="@/assets/illustration.png">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { loading, loginOtpByPhone, loginByPhone } from '@/core/auth'
import { showNotif } from '@/core/utility'
import { analytics } from '@/core/firebase-messaging'

export default {
  setup () {
    const router = useRouter()
    const errorMessage = ref('')
    const countDown = ref(120)
    const kode_otp = ref('')
    const no_hp = ref(useRoute().params.phone.replace('+62', '0'))

    const countDownTimer = () => {
      if (countDown.value > 0) {
        setTimeout(() => {
          countDown.value -= 1
          countDownTimer()
        }, 1000)
      }
    }

    const submit = async () => {
      try {
        loading.otp = true
        analytics.logEvent('check_otp')
        const { status, message } = await loginOtpByPhone({
          no_hp: no_hp.value,
          kode_otp: kode_otp.value,
          token_login: null // sementara null aja dulu
        })

        // Jika sukses
        if (status) {
          // redirect
          const user = JSON.parse(localStorage.getItem('user'))
          if (user.status_text === 'Belum Registrasi') {
            return router.push({ name: 'SettingPersonalData' })
          } else {
            return router.push({ name: 'Home' })
          }
        } else {
          // error
          showNotif({ status, message })

          analytics.logEvent('check_otp_failed', message)
          return
        }
      } catch (error) {
        showNotif({
          status: 'error',
          message: error.response.data.message
        })
        analytics.logEvent('check_otp_failed', error.response.data)
      } finally {
        loading.otp = false
      }
    }

    const resend = async () => {
      try {
        const { status, message } = await loginByPhone({
          phone: no_hp.value
        })

        if (status) {
          showNotif({
            status: 'success',
            message: message
          })
        } else {
          showNotif({
            status: 'error',
            message: message
          })
        }

        // Reset timer
        countDown.value = 120
        countDownTimer()
      } catch (error) {
        errorMessage.value = error.response.data.message
      }
    }

    onMounted(() => {
      countDownTimer()
    })

    return {
      loading,
      errorMessage,
      countDown,
      kode_otp,
      submit,
      resend
    }
  }
}
</script>

<style scoped>
  .form-login {
    padding-top: 25%;
  }

  .form-control.is-invalid, .input-group-text.is-invalid {
    box-shadow: inset 0px -1px 0px #E21F39;
  }

  .form-control::placeholder {
    color: #CBD5E0;
  }

  .form-control, .input-group-text {
    background: none;
    border-radius: 0;
    box-shadow: inset 0px -1px 0px rgba(242, 242, 242, 0.8);
    padding: 8px 4px 8px 0px;
    font-size: 18px;
  }

  .input-group>.form-control.form-error {
    background: #FFDDEB;
    color: #14142B;
  }

  .btn-login {
    border: none;
    padding: 10px 20px;
  }

  .frame-image {
    position: absolute;
    background: #007a9826;
    width: 56%;
  }

  .frame-image img {
    width: 90%;
    height: auto;
  }

  .spinner-border {
    width: 1rem;
    height: 1rem;
    animation-duration: 1.5s
  }

  @media (min-width: 360px) and (max-width: 767px) {
    .col-md-5 {
      padding-right: 0px !important;
    }

    .form-login {
      padding-right: 1rem !important;
    }

    .col-md-7 {
      padding-left: 0px;
    }

    .frame-image {
      position: relative;
      background: #007a9826;
      width: 100%;
    }
  }
</style>
